import React from 'react'

import Layout from '../components/layout'
import Container from '../components/container'
import Header from '../components/header'

const PhotographyPage = () => (
  <Layout>
    <Container>
      <Header roleTitle='photographers' />
    </Container>
  </Layout>
)

export default PhotographyPage
